import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styles from './styles.module.scss'

import degrees from './degrees.json'
import categories from './categories.json'

const SearchDegreesBlock = () => {
  const [selectedDegree, setSelectedDegree] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');

  useEffect( () => {
    const params = (new URL(document.location)).searchParams;

    if (!!params) {
      // Use page's url params to initially set selected options.
      const degree = params.get("degree") || null;
      const category = params.get("category") || null;
      const subject = params.get("subject") || null;

      !!degree && setSelectedDegree(degree);
      !!category && setSelectedCategory(category);
      !!subject && setSelectedSubject(subject);
    }

  }, []);

  const handleDegreeSelect = (e) => {
    setSelectedDegree(e.target.value)
  };

  const handleCategorySelect = (e) => {
    setSelectedCategory(e.target.value)
  };

  const handleSubjectSelect = (e) => {
    setSelectedSubject(e.target.value)
  };

  const getCategorySubjects = (categoryId) => {
    if (!categoryId) return [];

    const category = categories.find(item => item.value.toString() === categoryId);

    return category.subjects || [];
  };

  return (
      <div className={styles.searchDegrees}>
        <div className={styles.innerWrapper}>
          <h2 className={styles.title}>Search Degrees</h2>

          <div className={styles.form}>
            <select
                name="degrees"
                value={selectedDegree}
                className={styles.select}
                onChange={handleDegreeSelect}
            >
              <option value="">SELECT DEGREE</option>
              { degrees.map(item => (
                <option
                    value={item.value}
                    key={`degree-option--${item.value}`}
                >
                  {item.label}
                </option>
              ))}
            </select>

            <select
                name="categories"
                value={selectedCategory}
                className={styles.select}
                onChange={handleCategorySelect}
            >
              <option value="">SELECT CATEGORY</option>
              { categories.map(item => (
                  <option
                      value={item.value}
                      key={`category-option--${item.value}`}
                  >
                    {item.label}
                  </option>
              ))}
            </select>

            <select
                name="subjects"
                value={selectedSubject}
                className={styles.select}
                onChange={handleSubjectSelect}
                disabled={!selectedCategory || !getCategorySubjects(selectedCategory).length}
            >
              <option value="">SELECT SUBJECT</option>
              { getCategorySubjects(selectedCategory).map(item => (
                  <option
                      value={item.value}
                      key={`category-option--${item.value}`}
                  >
                    {item.label}
                  </option>
              ))}
            </select>
          </div>

          <Link
              className={styles.button}
              to={`/search?degree=${selectedDegree}&category=${selectedCategory}&subject=${selectedSubject}`}
          >
            Start now
          </Link >


        </div>
      </div>
  );
};

export default SearchDegreesBlock
