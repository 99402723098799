import React from "react";
import { Link, withPrefix } from 'gatsby'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const ArrowLink = ({ linkPath, linkTitle }) => (
  <Link to={withPrefix(linkPath)} className={styles.arrowLink}>
    {linkTitle}
  </Link>
);

ArrowLink.propTypes = {
  linkPath: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
};

export default ArrowLink;
