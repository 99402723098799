import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import ItemsSlider from '../../../ItemsSlider'
import { escapeHtmlTags, truncateString } from '../../../../utils/helper'

const EducatorInFocusSlider = ( {data} ) => {
  const slidesData = data.allMarkdownRemark.edges;

  const getSlideText = (html) => {
    let text = escapeHtmlTags(html);
    return truncateString(text, 300, 340, 380);
  };

  const slides = slidesData.map(item => {
    return {
      image: item.node.frontmatter.featuredImage,
      title: item.node.frontmatter.title,
      text: getSlideText(item.node.html),
      link: item.node.fields.slug
    }
  });

  return (
    <div className={styles.studentGuideSlider}>
      { !!slides.length &&
        <ItemsSlider
            sliderName="Educator in Focus"
            sliderSectionLink="/blog#educator-in-focus"
            slides={slides}
        />
      }
    </div>
  )
};

EducatorInFocusSlider.propTypes = {
  data: PropTypes.object
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogSliderQuery {
        allMarkdownRemark(
            filter: {frontmatter: {tags: {in: "Educator in Focus"}}}, 
            sort: {fields: frontmatter___date, order: DESC}
        ) {
          edges {
            node {
              html
              frontmatter {
                date
                featuredImage
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={ (data) => <EducatorInFocusSlider data={data} /> }
  />
)
