import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import ArrowLink from '../../../ArrowLink'
import Image from '../../../Image'
import {withPrefix} from "../../../../../.cache/gatsby-browser-entry";

const ItemSlide = ({ sliderName, sliderSectionLink, slideData}) => (
  <div className={styles.itemSlide}>
    
    <Link to={withPrefix(slideData.link)} className={styles.slideImage}>
      <Image imgName={slideData.image} />
    </Link>
    
    <div className={styles.contentWrapper}>
      
      <Link className={styles.sliderNameLink} to={sliderSectionLink} title={sliderName}>
        <h2 className={styles.sliderNameHeading}>
          {sliderName}
        </h2>
      </Link>

      <Link to={withPrefix(slideData.link)} className={styles.titleLink}>
        <h3 className={styles.slideTitle}>
          {slideData.title}
        </h3>
      </Link>
      
      <div className={styles.slideText}>
        {slideData.text}
      </div>
      
      <ArrowLink linkPath={slideData.link} linkTitle="Read More" />
    </div>
  </div>
);

ItemSlide.propTypes = {
  name: PropTypes.string,
  slideData: PropTypes.exact({
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string,
  }).isRequired,
};

export default ItemSlide
