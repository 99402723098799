import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import MainMenu from "./MainMenu";
import styles from './styles.module.scss';
import logo from '../../../../assets/img/logo.png';
import classNames from 'classnames';

const Header = ({ isHomepage }) => {
  
  return (
    <header className={classNames(styles.header, {[styles.headerHome]: isHomepage})}>
      <div className={styles.innerWrapper}>
        <div className={styles.branding}>
          <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
          >
            <img src={logo} className={styles.logoImage} alt="Academic Info logo"/>
          </Link>
        </div>
  
        <MainMenu/>
      </div>
    </header>
  )
};

Header.propTypes = {
  isHomepage: PropTypes.bool,
};

export default Header
