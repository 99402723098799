import React from "react"
import styles from './styles.module.scss';

const Hero = () => {
  
  return (
    <section className={styles.hero}>
      <div className={styles.innerWrapper}>
        <h1 className={styles.heading}>
          Find the next step on your journey
        </h1>
        
        <p className={styles.subHeading}>
          Your college search begins here
        </p>
      </div>
    </section>
  )
};

export default Hero
