import React from "react"
import { Link } from "gatsby"
import styles from './styles.module.scss';

const Footer = () => (
  <nav
      className={styles.footerMenu}
      role="navigation"
      aria-label="main-navigation"
  >
    <Link className={styles.link} to="/cdp.html" activeClassName={styles.active}>
      About
    </Link>

    <Link className={styles.link} to="/privacy-policy" activeClassName={styles.active}>
      Privacy
    </Link>

    <Link className={styles.link} to="/terms" activeClassName={styles.active}>
      Terms
    </Link>

    <Link className={styles.link} to="/advertising-disclosure" activeClassName={styles.active}>
      Advertising Disclosure
    </Link>

    <Link className={styles.link} to="/accessibility-statement">
      Accessibility Statement
    </Link>

    <Link className={styles.link} to="/contact" activeClassName={styles.active}>
      Contact
    </Link>
  </nav>
);

export default Footer
