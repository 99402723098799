import React from "react"
import { graphql, StaticQuery, Link, withPrefix } from "gatsby"
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const CollegesByState = ( {data} ) => {
  const states = data.allStatePage.edges;
  
  return (
    <section className={styles.collegesByState}>
      <h2 className={styles.title}>Colleges by state</h2>
      
      <div className={styles.linksWrapper}>
        {states.map( (item, index) => (
            <Link to={withPrefix(item.node.path)} key={index} className={styles.link}>
              {item.node.title.substring(0, item.node.title.indexOf(' Colleges'))}
            </Link>
        ))}
      </div>
    </section>
  )
};

CollegesByState.propTypes = {
  data: PropTypes.object
};

export default () => (
  <StaticQuery
    query={graphql`
      query CollegesByStateQuery {
        allStatePage(sort: {fields: title, order: ASC}) {
          edges {
            node {
              path
              title
            }
          }
        }
      }
    `}
    render={ (data) => <CollegesByState data={data} /> }
  />
)
