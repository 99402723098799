import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import ItemsSlider from '../../../ItemsSlider'
import { escapeHtmlTags, truncateString } from '../../../../utils/helper'

const StudentGuideSlider = ( {data} ) => {
  const slidesData = data.allCategoryPage.edges;
  
  const getSlideText = (html) => {
    let text = escapeHtmlTags(html);
    return truncateString(text, 300, 340, 380);
  };
  
  const slides = slidesData.map(item => {
    return {
      image: item.node.image,
      title: item.node.title,
      text: getSlideText(item.node.body),
      link: item.node.path
    }
  });
  
  return (
    <div className={styles.studentGuideSlider}>
      <ItemsSlider sliderName="Student Guide" sliderSectionLink="/student-guide" slides={slides} />
    </div>
  )
};

StudentGuideSlider.propTypes = {
  data: PropTypes.object
};

export default () => (
  <StaticQuery
    query={graphql`
      query StudentGuideSliderQuery {
        allCategoryPage(filter: {sections: {elemMatch: {section: {eq: "study-guide"}}}}) {
          edges {
            node {
              title
              path
              body
              image
            }
          }
        }
      }
    `}
    render={ (data) => <StudentGuideSlider data={data} /> }
  />
)
