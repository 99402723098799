import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import styles from './styles.module.scss';
import logo from '../../../../assets/img/logo.png';
import FooterMenu from './FooterMenu'

const Footer = () => {
  const data = useStaticQuery(graphql`
      query SiteNameQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `);
  
  return (
    <footer className={styles.footer}>
      <div className={styles.innerWrapper}>
  
        <div className={styles.footerDescription}>
          <p>
            This site was founded by librarian Mike Madin in 1998. After more than 20 years on the Internet, AcademicInfo has become the premier website for education and university related information and reviews.
          </p>
          <p>
            While we do our very best to provide accurate and up-to-date information, please contact us if you find anything that is either wrong, or that you feel is missing from the site.
          </p>
          <p>
            Copyright © 1998 - {new Date().getFullYear()} {data.site.siteMetadata.title}. All rights reserved.
          </p>
        </div>
  
        <div className={styles.footerMenuColumn}>
          <img className={styles.logo} src={logo} alt="Academic Info logo"/>
  
          <FooterMenu />
          
        </div>
        
      </div>
    </footer>
  )
};

export default Footer
