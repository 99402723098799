import React from 'react'
import PropTypes from 'prop-types'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import ItemSlide from './components/ItemSlide'
import SliderArrow from './components/SliderArrow'
import styles from './styles.module.scss'

// pure-react-carousel docs:
// https://www.npmjs.com/package/pure-react-carousel

const ItemsSlider = ({
                       sliderName,
                       sliderSectionLink,
                       slides,
                    }) => (
  <CarouselProvider
      className={styles.itemsSlider}
      isIntrinsicHeight={true}
      naturalSlideWidth={100}
      naturalSlideHeight={33}
      totalSlides={slides.length}
  >
    <Slider>
      {slides.map((item, index) => (
        <Slide index={index} key={index}>
          <ItemSlide sliderName={sliderName} sliderSectionLink={sliderSectionLink} slideData={item}/>
        </Slide>
      ))}
    </Slider>
    
    <ButtonBack className={styles.back}>
      <SliderArrow direction="left" />
    </ButtonBack>
    
    <ButtonNext className={styles.next}>
      <SliderArrow />
    </ButtonNext>
  </CarouselProvider>
);

ItemsSlider.propTypes = {
  sliderName: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.exact({
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string,
  })).isRequired,
  slideWidth: PropTypes.number,
  slideHeight: PropTypes.number,
};

export default ItemsSlider;
