import React from "react"
import PropTypes from "prop-types"
import Header from './components/Header'
import Hero from './components/Hero'
import SearchDegreesBlock from './components/SearchDegreesBlock'
import DegreesBySubject from './components/DegreesBySubject'
import CollegesByState from './components/CollegesByState'
import Footer from './components/Footer'
import classNames from 'classnames'
import StudentGuideSlider from './components/StudentGuideSlider'
import EducatorInFocusSlider from './components/EducatorInFocusSlider'
import styles from './styles.module.scss'
import 'normalize.css'
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'
import Helmet from "react-helmet";

const Layout = ({ children, isHomepage, hideSidebar }) => {

  return (
    <>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9231860735104128"
          crossorigin="anonymous"
        />
      </Helmet>

      <div className={styles.mainLayout}>
        <Header isHomepage={isHomepage}/>

        { isHomepage && <Hero/> }

        <div className={classNames(styles.mainContainer, isHomepage && styles.homepage)}>

          <div className={!isHomepage ? styles.hWrapper : undefined}>
            {!hideSidebar &&
              <aside className={styles.sidebar}>
                <SearchDegreesBlock/>
              </aside>
            }

            <div className={styles.contentWrapper}>
              <main className={styles.mainContent}>
                {children}
              </main>
            </div>
          </div>

          <div className={styles.pageBottom}>
            <EducatorInFocusSlider />

            <div className={styles.hWrapper}>
              <DegreesBySubject />
              <CollegesByState />
            </div>

            <StudentGuideSlider />
          </div>
        </div>

        <Footer/>
      </div>
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHomepage: PropTypes.bool,
};

export default Layout
