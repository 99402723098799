import React, { useState } from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import styles from './styles.module.scss'

const MainMenu = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpened(!menuOpened);
  };

  return (
    <nav
        className={classNames(styles.menu, menuOpened && styles.mobileMenuActive)}
        role="navigation"
        aria-label="main-navigation"
    >
      <div
          className={styles.menuToggleBtn}
          onClick={handleMenuToggle}
          onKeyDown={handleMenuToggle}
          role="button"
          tabIndex={0}
      >
        <span className={styles.menuIconLine}/>
      </div>

      <div className={styles.menuItemsWrapper}>
        <Link className={styles.link} to="/" activeClassName={styles.active}>
          Home
        </Link>

        <Link className={styles.link} to="/eddist.html" activeClassName={styles.active}>
          Degrees
        </Link>

        <Link className={styles.link} to="/studentcolleges.html" activeClassName={styles.active}>
          Colleges
        </Link>

        <Link className={styles.link} to="/blog" activeClassName={styles.active}>
          Articles
        </Link>

        <Link className={styles.link} to="/student-guide" activeClassName={styles.active}>
          Guide
        </Link>
      </div>

      <div hidden={!menuOpened} onClick={handleMenuToggle} className={styles.menuBackdrop}/>
    </nav>
  )
};

export default MainMenu
