import parse from 'html-react-parser'

/**
 * Process body to split into sections: "Degree Information", "FAQ", "Student Guide".
 * Using <h2> headings as sections dividers.
 *
 * @param {String} body
 * @returns {{degreeInfo: [], faq: [], studentGuide: []} | {}}
 */
export function getBodySections(body) {
  let bodyData = parse(body);
  
  let sectionHeadings = [];
  
  let parsedBody = {};
  
  // Find <h2> headings which represent sections.
  if (bodyData.length) {
    bodyData.forEach((item, index) => {
      if (item.type === 'h2') {
        let name = 'default';
        
        if (!!item.props.children) {
          const heading = item.props.children.toString().toLowerCase();
  
          if (heading.includes("degree information")) {
            name = 'degree-info'
          } else if (heading.includes("faq")) {
            name = 'faq';
          } else if (heading.includes("student guide")) {
            name = 'student-guide'
          }
  
          sectionHeadings.push({name: name, index: index});
        }
      }
    });
  }
  else return null;
  
  // Split body into sections relying on <h2> headings positions.
  for (let i = 0; i < sectionHeadings.length; i++) {
    let end = sectionHeadings[i+1] ? sectionHeadings[i+1].index : bodyData.length;
    
    parsedBody[sectionHeadings[i].name] = bodyData.slice(sectionHeadings[i].index, end);
  }
  
  return parsedBody;
}

/**
 * Get <h2> tag from given section.
 *
 * @param {Object[]} section
 * @returns {Object}
 */
export function getSectionHeading(section) {
  let sectionHeading = '';
  
  section.forEach(item => {
    if (item.type === 'h2') {
      sectionHeading = item;
    }
  });
  
  return sectionHeading;
}

/**
 * Split section into separate items using <h3> headings positions as items dividers.
 *
 * @param {Object[]} section
 * @returns {Object[]}
 */
export function getSectionItems(section) {
  let itemHeadings = [];
  let items = [];
  
  // Find h3 headings which represent section items.
  section.forEach((item, index) => {
    if (item.type === 'h3') {
      itemHeadings.push(index);
    }
  });
  
  // Separate section items relying on <h3> headings positions.
  for (let i = 0; i < itemHeadings.length; i++) {
    let end = itemHeadings[i+1] ? itemHeadings[i+1] : section.length;
    items.push(section.slice(itemHeadings[i], end));
  }
  
  return items;
}

/**
 * Smart text trimming using '.', '!', '?' characters positions.
 *
 * @param {String} text
 * @param {Number} minTextLength
 * @param {Number} textLength
 * @param {Number} maxTextLength
 * @returns {string}
 */
export function truncateString( text, minTextLength, textLength, maxTextLength ) {
  let spanString = text.substring(minTextLength, maxTextLength);
  let trimPosition = spanString.indexOf('.') !== -1 ? spanString.indexOf('.')
      : spanString.indexOf('!') !== -1 ? spanString.indexOf('!')
          : spanString.indexOf('?');
  
  const length = trimPosition !== -1 ? trimPosition + minTextLength + 1 : textLength;
  text = text.substring(0, length);
  
  return trimPosition !== -1 ? text : text + '...';
}

/**
 * @param {String} html
 * @returns {String}
 */
export function escapeHtmlTags(html) {
  return html.replace(/(<([^>]+)>)/ig,"");
}
