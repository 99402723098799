import React from "react"
import arrowSmallSrc from '../../../../assets/img/arrow-small.svg';
import styles from './styles.module.scss'

const SliderArrow = ({ direction = 'right' }) => (
  <div className={styles.sliderArrow}>
    <img src={arrowSmallSrc} alt="arrow" className={styles[direction]}/>
  </div>
);

export default SliderArrow;
